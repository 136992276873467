<template>
  <div>
    <Drawer
        :title="title"
        v-model="isShow"
        :width="width"
        :mask-closable="false"
        :closable="false"
        :styles="styles"
    >
      <div>
        <slot name="default"></slot>
      </div>
      <div class="demo-drawer-footer">
        <slot name="drawer-footer"></slot>
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: String,
    width: {
      type: Number,
      default: 700
    }
  },
  data () {
    return {
      styles: {
        height: 'calc(100% - 100px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
    }
  }
}
</script>
<style>
.demo-drawer-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>